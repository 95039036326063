/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

.ecommerce button.collapsed::after {
    content: "\f0415";
    top: 50%;
}

.ecommerce button::after {
    content: "\f0374";
    display: block;
    font-family: "Material Design Icons";
    font-size: 16px;
    position: absolute;
    right: 20px;
    font-weight: 500;
    top: 30%;
    background-image: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.empty-state__image {
    width: 200px; /* ajusta el tamaño de la imagen según sea necesario */
    height: auto; /* para mantener la proporción de aspecto */
}

.empty-state__message {
    margin-top: 5px; /* ajusta el margen superior según sea necesario */
    font-size: 18px; /* ajusta el tamaño de fuente según sea necesario */
    color: #868686; /* ajusta el color del texto según sea necesario */
}