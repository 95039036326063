// 
// preloader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $card-bg;
    z-index: 9999;
}

.spinner{
    .spin-icon {
        font-size: 56px;
        color: $primary;
        position: relative;
        display: inline-block;
        animation: spin 1.6s infinite linear;
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
}
  