//
// Forms.scss
//


// checkbox input right

.form-check-right{
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;;
  .form-check-input{
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
  .form-check-label{
    display: block;
  }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;
}


.form-check-label{
  cursor: pointer;
  margin-bottom: 0;
}

.select2-container .select2-selection--single {
  line-height: 1.5 !important;
  height: 38px !important;
}

.select2-container--default .select2-selection--single {
  border: var(--bs-border-width) solid var(--bs-border-color-translucent) !important;
  line-height: 1.5 !important;
}

.select2-dropdown {
  background-color: var(--bs-secondary-bg) !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px !important;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  height: 38px !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px !important;
}

.select2 select2-container select2-container--default {
  width: unset !important;
}