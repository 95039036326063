// 
// Task.scss
//

.task-box{
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: none;

  .team{
    .team-member{
      margin-right: 6px;
    }
  }
}

.gu-transit {
    border: 1px dashed var(--#{$prefix}border-color) !important;
    background-color: $gray-200 !important;
}
