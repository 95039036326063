//
// Form editors.scss
//

// Tinymce 

.tox-tinymce {
    border: 2px solid var(--#{$prefix}border-color) !important;
}

.tox {
    .tox-statusbar {
        border-top: 1px solid var(--#{$prefix}border-color) !important;
    }

    .tox-menubar,
    .tox-edit-area__iframe,
    .tox-statusbar {
        background-color: var(--#{$prefix}secondary-bg)!important;
        background: none !important;
    }

    .tox-mbtn {
        color: var(--#{$prefix}body-color) !important;

        &:hover:not(:disabled):not(.tox-mbtn--active) {
            background-color: var(--#{$prefix}tertiary-bg) !important;
        }
    }

    .tox-tbtn {
        &:hover {
            background-color: var(--#{$prefix}tertiary-bg) !important;
        }
    }

    .tox-toolbar__primary {
        border-color: var(--#{$prefix}border-color)!important;
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
        background: var(--#{$prefix}tertiary-bg) !important;
    }

    .tox-tbtn {
        color: var(--#{$prefix}body-color) !important;

        svg {
            fill: var(--#{$prefix}body-color)!important;
        }
    }

    .tox-edit-area__iframe {
        background-color:var(--#{$prefix}secondary-bg) !important;
    }

    .tox-statusbar a,
    .tox-statusbar__path-item,
    .tox-statusbar__wordcount {
        color: var(--#{$prefix}body-color) !important;
    }

    &:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
        border-right: 1px solid var(--#{$prefix}border-color) !important;
    }
}
.tox-tinymce-aux {
    z-index: 1050 !important;
}


.tox .tox-toolbar-overlord {
    background-color: var(--#{$prefix}tertiary-bg) !important;
}
